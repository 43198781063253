import { ErrorHandler, Injectable, inject } from '@angular/core';
import { AppInsightsWebService } from '../../../../../libs/app-insights-web/src/lib/app-insights-web.service';

@Injectable({
    providedIn: 'root',
})
export class ErrorHandlerService extends ErrorHandler {
    private readonly _appInsightsWebService = inject(AppInsightsWebService);

    constructor() {
        super();
    }

    public handleError(error: Error): void {
        this._appInsightsWebService.logException(error);
    }
}
