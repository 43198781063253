import { Injectable } from '@angular/core';
// import { ApplicationInsights } from '@microsoft/applicationinsights-web';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { AppInsightsWebInitializerService } from './app-insights-web-initializer.service';

@Injectable()
export class AppInsightsWebService {
    // appInsights!: ApplicationInsights;

    constructor() {
        AppInsightsWebInitializerService.init$.pipe(takeUntilDestroyed()).subscribe((instrumentationKey) => {
            // this.appInsights = new ApplicationInsights({
            //   config: {
            //     instrumentationKey,
            //     enableAutoRouteTracking: true, // option to log all route changes
            //     maxBatchInterval: 100
            //   }
            // });
            // this.appInsights.loadAppInsights();
        });
    }

    public logPageView(name?: string, url?: string): void {
        // option to call manually
        // this.appInsights.trackPageView({
        //   name: name,
        //   uri: url
        // });
    }

    public logEvent(name: string, properties?: { [key: string]: any }): void {
        // this.appInsights.trackEvent({ name: name }, properties);
    }

    public logMetric(name: string, average: number, properties?: { [key: string]: any }): void {
        // this.appInsights.trackMetric({ name: name, average: average }, properties);
    }

    public logException(exception: Error, severityLevel?: number): void {
        console.error(exception);
        // if(this.appInsights) {
        //   this.appInsights.trackException({ exception: exception, severityLevel: severityLevel });
        // } else {
        //   console.error(exception)
        // }
    }

    public logTrace(message: string, properties?: { [key: string]: any }): void {
        // this.appInsights.trackTrace({ message: message }, properties);
    }
}
