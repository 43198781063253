import { isPlatformServer } from '@angular/common';
import { Injectable, PLATFORM_ID, inject } from '@angular/core';
import { ReplaySubject } from 'rxjs';

@Injectable({
    providedIn: 'root',
})
export class AppInsightsWebInitializerService {
    private readonly _platformId = inject(PLATFORM_ID);

    static readonly init$ = new ReplaySubject<string>(1);

    public init(instrumentationKey: string): void {
        if (isPlatformServer(this._platformId)) {
            return;
        }

        AppInsightsWebInitializerService.init$.next(instrumentationKey);
        AppInsightsWebInitializerService.init$.complete();
    }
}
